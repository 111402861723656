import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import activeAssetSlice  from './activeAssetSlice';
import activeSpaceSlice from './activeSpaceSlice';
import assetImageSlice from './assetImageSlice';
import floorplanReducer from './floorPlanSlice';
import streamingSlice  from './streamingSlice';
import spaceLabelingSlice from './spaceLabelingSlice';


export const store = configureStore({
  reducer: {
    floorPlan: floorplanReducer,
    activeSpace: activeSpaceSlice,
    activeAsset: activeAssetSlice,
    assetImages: assetImageSlice,
    floors: streamingSlice,
    spaceLabelingApp : spaceLabelingSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
