import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import Media from 'react-media';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect, Route, Switch
} from "react-router-dom";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './slices/store';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000"

export const loadingComponent = <div className="spinner-wrapper"> <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
const deviceNotSupportedComponent = <div><img className='device-not-supported' src={require("./warning.png")} alt="" /></div>

const PlatformAppLoader = Loadable({
  loader: () => import(/* webpackChunkName: "platformdemo" */ './platform-app/App'),
  loading: () => loadingComponent
});

const StreamingAppLoader = Loadable({
  loader: () => import(/* webpackChunkName: "streamingapp" */ './streaming-app/StreamingApp'),
  loading: () => loadingComponent
});

const StreamingAppSmallLoader = Loadable({
  loader: () => import(/* webpackChunkName: "streamingapp" */ './streaming-app/StreamingAppSmall'),
  loading: () => loadingComponent
});

const CompareFloorsLoader = Loadable({
  loader: () => import(/* webpackChunkName: "streamingapp" */ './platform-app/app/CompareFloors'),
  loading: () => loadingComponent
});

const SpaceLabelingAppLoader = Loadable({
  loader: () => import(/* webpackChunkName: "streamingapp" */ './space-labeling-app/SpaceLabelingApp'),
  loading: () => loadingComponent
});

const ThreejsAppLoader = Loadable({
  loader: () => import(/* webpackChunkName: "threejsapp" */ './threejs-app/App'),
  loading: () => loadingComponent
});

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/platform">
            <Media query="(max-width: 500px)" render={() => deviceNotSupportedComponent} />
            <Media query="(min-width: 501px)" render={() => <div className="main-app"><PlatformAppLoader /></div>} />
          </Route>
          <Route exact path="/compare-floors">
            <Media query="(max-width: 500px)" render={() => deviceNotSupportedComponent} />
            <Media query="(min-width: 501px)" render={() => <div className="main-app"><CompareFloorsLoader /></div>} />
          </Route>
          <Route exact path="/real-time-demo">
            <Media query="(max-width: 500px)" render={() => <StreamingAppSmallLoader />} />
            <Media query="(min-width: 501px)" render={() => <StreamingAppLoader />} />
          </Route>
          <Route exact path="/space-labeling-app">
            <Media query="(max-width: 500px)" render={() => deviceNotSupportedComponent} />
            <Media query="(min-width: 501px)" render={() => <SpaceLabelingAppLoader />} />
          </Route>
          <Route exact path="/threejs-demo">
            <Media query="(max-width: 500px)" render={() => deviceNotSupportedComponent} />
            <Media query="(min-width: 501px)" render={() => <ThreejsAppLoader />} />
          </Route>
          <Route path="/">
            <Redirect to="/platform" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
