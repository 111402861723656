import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
// import { AppThunk, RootState } from '../../streaming-app/app/store';
import { AppThunk, RootState } from './store';


export const floorsIds = [
    '1ca271b9-7975-4a2c-8b62-ca118d374295', // 175 5th av
    'b6aa6096-bb77-4872-be25-4886a9e5bf06', // washintong place
    '77188708-3cac-4f21-996c-15fa2e3dcb1a', // Lux office
    // '1688fdb9-7ce2-47be-941c-3ed76144047b', // Mythenquai
    // 'd16bbfc1-68e9-4b94-8608-5478687796e9', // 1001 fanin
    // 'c725517e-3cc0-4792-b382-b00f3f2cd52c' // 32 london bridge
]


export interface Floor {
    id: string;
    name: string;
}


interface FloorSliceState {
    floors: Floor[];
    selectedFloorId: string | null,
    loading: boolean;
    token: string | null;
}

const initialState: FloorSliceState = {
    floors: [],
    selectedFloorId: null,
    loading: false,
    token: null
};

export const streamingSlice = createSlice({
    name: 'floors',
    initialState,
    reducers: {
        setFloors: (state, action: PayloadAction<Floor[]>) => {
            state.floors = action.payload;
        },
        setSelectedFloorId: (state, action: PayloadAction<string>) => {
            state.selectedFloorId = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        startFetching: state => {
            state.loading = true
        },
        endFetching: state => {
            state.loading = false
        },
    },
});

export const {
    setFloors,
    setSelectedFloorId,
    setToken,
    startFetching,
    endFetching,
} = streamingSlice.actions;

export const floors = (state: RootState) => state.floors;

export const getFloors = (): AppThunk => (dispatch, getState) => {
    dispatch(startFetching())
    const token = getState().floors.token
    let floors: Floor[] = []
    const allRequests = floorsIds.map(floorId => Axios.get(`${process.env.REACT_APP_API_BASE_URL}/v2/floor/${floorId}/custom-field/properties.customFields`, {
        headers: {
            'Authorization': token
        }
    }))

    const allRequestsResponses = Axios.all(allRequests)

    allRequestsResponses.then(Axios.spread((...responses) => {
        floors = responses.map((response, index) => {
            const listingPageCustomdata = response?.data?.listingPage

            return {
                id: floorsIds[index],
                name: listingPageCustomdata?.data?.address,
            }
        })

        dispatch(setFloors(floors))
    })).catch(errors => {
        console.error(errors)
    }).finally(() => {
        dispatch(endFetching())
    })
};

export const getToken = (): AppThunk => dispatch => {
    let tempToken: null | string = null
    Axios.get(`${process.env.REACT_APP_BACKEND_URL}/temp-token`).then(response => {
        tempToken = response?.data?.authorization
        if (!tempToken) return;

        dispatch(setToken(tempToken))


    })
}









export default streamingSlice.reducer;
