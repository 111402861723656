import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios from "axios";
import { AppThunk, RootState } from "./store";

export interface SpacesLabels {
  [key: string]: string;
}

interface FloorSliceState {
  sceneId: string | null;
  name: string | null;
  address: string | null;
  loading: boolean;
  token: string | null;
  labels: SpacesLabels | null;
  ocrLabels: SpacesLabels | null;
  selectedSpaceId: string | null;
  updateCompleted: boolean;
}

const initialState: FloorSliceState = {
  sceneId: null,
  name: null,
  address: null,
  loading: false,
  token: null,
  selectedSpaceId: null,
  labels: null,
  updateCompleted: false,
  ocrLabels: null,
};

export const streamingSlice = createSlice({
  name: "floors",
  initialState,
  reducers: {
    setSelectedFloorId: (state, action: PayloadAction<string>) => {
      state.sceneId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setSelectedSpaceId: (state, action: PayloadAction<string | null>) => {
      state.selectedSpaceId = action.payload;
    },
    setLabels: (state, action: PayloadAction<SpacesLabels>) => {
      state.labels = action.payload;
    },
    startFetching: (state) => {
      state.loading = true;
    },
    endFetching: (state) => {
      state.loading = false;
    },
    setUpdateStart: (state) => {
      state.updateCompleted = false;
    },
    setUpdateCompleted: (state) => {
      state.updateCompleted = true;
    },
    setSpaceLabel: (state, action: PayloadAction<SpacesLabels>) => {
      state.labels = { ...state.labels, ...action.payload };
    },
    setOcrLabels: (state, action: PayloadAction<SpacesLabels>) => {
      state.ocrLabels = { ...state.ocrLabels, ...action.payload };
    },
  },
});

export const {
  setSelectedFloorId,
  setToken,
  startFetching,
  endFetching,
  setSelectedSpaceId,
  setLabels,
  setUpdateCompleted,
  setUpdateStart,
  setSpaceLabel,
  setOcrLabels,
} = streamingSlice.actions;

export const floors = (state: RootState) => state.floors;

export const getFloor = (floorId: string): AppThunk => (dispatch, getState) => {
  dispatch(startFetching());
  const token = getState().spaceLabelingApp.token;

  Axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/v2/floor/${floorId}?includeCustomFields=true`,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      const floorPlanData = response.data;
      const spacesIds = floorPlanData.resourceRelations.spaces;
      let labels: SpacesLabels = {};

      spacesIds.forEach((id: string) => {
        labels[id] = "";
      });

      const customFields = floorPlanData.properties.customFields;

      if (customFields && customFields.labels) {
        labels = { ...labels, ...customFields.labels };
      }

      dispatch(setLabels(labels));
      console.log(labels);
      console.log(floorPlanData.resourceRelations);
    })
    .catch((errors) => {
      console.error(errors);
    })
    .finally(() => {
      dispatch(endFetching());
    });
};

export const updateSpaceLabel = (spaceId: string, label: string): AppThunk => (
  dispatch,
  getState
) => {
  const sceneId = getState().spaceLabelingApp.sceneId;
  const token = getState().spaceLabelingApp.token;
  dispatch(setUpdateStart());
  Axios.put(
    `/v2/floor/${sceneId}/custom-field/properties.customFields.labels.${spaceId}`,
    JSON.stringify(label),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
    }
  )
    .then(() => {
      dispatch(setSpaceLabel({ [spaceId]: label }));
    })
    .finally(() => {
      dispatch(setUpdateCompleted());
    });
};

export const updateOcrSpacesLabels = (labels: SpacesLabels): AppThunk => (
  dispatch,
  getState
) => {
  dispatch(setOcrLabels(labels));
};

export const getToken = (): AppThunk => (dispatch) => {
  let tempToken: null | string = null;
  Axios.get(`${process.env.REACT_APP_BACKEND_URL}/temp-token`).then(
    (response) => {
      tempToken = response?.data?.authorization;
      if (!tempToken) return;

      dispatch(setToken(tempToken));
    }
  );
};

export default streamingSlice.reducer;
