import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { AppThunk } from './store';
import { setName as setActiveAssetName } from './activeAssetSlice';

interface ISpaceAPIData {
    image: string;
    description: string;
}


interface ActiveSpaceState {
    loading: boolean;
    id: string | null;
    area: string | null;
    seats: string | null;
    spaceAPIData: ISpaceAPIData;
    name: string | null;
}

const initialSpaceAPIData: ISpaceAPIData = {
    image: '',
    description: '',
}

const initialState: ActiveSpaceState = {
    loading: false,
    id: null,
    area: null,
    seats: null,
    name: null,
    spaceAPIData: initialSpaceAPIData,

};

export const activeSpaceSlice = createSlice({
    name: 'activeSpace',
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<string | null>) => {
            state.id = action.payload;
        },
        setName: (state, action: PayloadAction<string | null>) => {
            state.name = action.payload;
        },

        setArea: (state, action: PayloadAction<string | null>) => {
            state.area = Math.round(parseFloat(action.payload!)).toString();
        },
        setSeats: (state, action: PayloadAction<string | null>) => {
            state.seats = action.payload;
        },
        setSpaceAPIData: (state, action: PayloadAction<ISpaceAPIData>) => {
            state.spaceAPIData = action.payload;
        },
        startFetching: state => {
            state.loading = true
        },
        endFetching: state => {
            state.loading = false
        }
    },
});

export const {
    setId,
    setSeats,
    setArea,
    setName,
    setSpaceAPIData,
    startFetching,
    endFetching
} = activeSpaceSlice.actions;


export const selectSpace = (spaceId: string | null): AppThunk => dispatch => {
    dispatch(setId(spaceId))
    dispatch(setActiveAssetName(null))
    dispatch(getSpaceData(spaceId!))
}

export const getSpaceData = (spaceId: string): AppThunk => async dispatch => {
    dispatch(startFetching())

    const response = await Axios.get(`/v2/space/${spaceId}`)

    const activeSpaceName = response?.data?.properties?.usage
    dispatch(setName(activeSpaceName))

    const responseCustomFields = await Axios.get(`/v2/space/${spaceId}/custom-field/properties.customFields`)

    const spaceDescription = responseCustomFields?.data?.platformDemo?.spaceDescription
    const activeSpaceImage = responseCustomFields?.data?.platformDemo?.image
    dispatch(setSpaceAPIData({ description: spaceDescription, image: activeSpaceImage }))

    dispatch(endFetching())

};



export default activeSpaceSlice.reducer;
