import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { AppThunk } from './store';

export interface imagesListByProductId{
  id: string;
  image: string;
}

export interface AssetImageSlice {
  imagesListByProductId: imagesListByProductId[];
  loading: boolean
}

const initialState: AssetImageSlice = {
  imagesListByProductId: [],
  loading: false
};

export const assetImageSlice = createSlice({
  name: 'assetImages',
  initialState,
  reducers: {
    setImage: (state, action: PayloadAction<any>) => {
      state.imagesListByProductId = action.payload;
    },
    startFetching: state => {
      state.loading = true
    },
    endFetching: state => {
      state.loading = false
    },
  },
});

export const {
  setImage,
  startFetching,
  endFetching
} = assetImageSlice.actions;

export const getAssetsImage = (assetsId: string[]): AppThunk => dispatch => {
  let assetImageData: any[] = []

  const allRequests = assetsId.map(id => Axios.get(`https://storage.3d.io/archilogic/product/json/${id}.json`))

  Axios.all(allRequests).then(Axios.spread((...responses) => {
    assetImageData = responses.map(response => {

      const data = response.data
      const image = `https://storage.3d.io${data?.preview}`
      return {
        id: data.productResourceId,
        image
      }

    })

    dispatch(setImage(assetImageData))
  })).catch(errors => {
    // react on errors.
  })

}

export default assetImageSlice.reducer;
