import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios, { AxiosRequestConfig } from 'axios';
import { AppThunk, RootState } from './store';
import { setName as setActiveAssetName } from './activeAssetSlice';
import { getSpaceData, setId as setActiveSpaceId } from './activeSpaceSlice';


export interface IFloorAPIData {
  number: string;
  address: string;
  image: string;
  description: string;
  pricelist: any;
  latitude: any;
  longitude: any;
}

export interface IDescriptionImage {
  description: string;
  image: string;
}

export interface IFloorFPEData {
  area: number;
  circulation: number;
  headcount: number;
  areaPerHeadcount: number;
  windowArea: number;
  doorCount: number;
  windowCount: number;
}

interface Coordinate {
  lat: number;
  long: number;
}

export interface IFloorBasicData {
  id: string;
  name: string;
  coordinates: Coordinate
}

const initialFloorAPIData: IFloorAPIData = {
  number: '',
  address: '',
  image: '',
  description: '',
  pricelist: {},
  latitude: '',
  longitude: ''
}
const initialFloorFPEData: IFloorFPEData = {
  area: 0,
  circulation: 0,
  headcount: 0,
  areaPerHeadcount: 0,
  windowArea: 0,
  doorCount: 0,
  windowCount: 0
}

interface FloorPlanState {
  sceneId: string | null;
  floorFPEData: IFloorFPEData;
  floorAPIData: IFloorAPIData;
  floorBasicData: IFloorBasicData[];
  loading: boolean;
  descriptionEditMode: boolean;
  loadingFpe: boolean;
  tempToken: string;
}

const initialState: FloorPlanState = {
  sceneId: null,
  floorFPEData: initialFloorFPEData,
  floorBasicData: [],
  floorAPIData: initialFloorAPIData,
  loading: false,
  descriptionEditMode: false,
  loadingFpe: false,
  tempToken: ''
};

export const floorPlanSlice = createSlice({
  name: 'floorplan',
  initialState,
  reducers: {
    setSceneId: (state, action: PayloadAction<string>) => {
      state.sceneId = action.payload;
    },
    setFloorFPEData: (state, action: PayloadAction<IFloorFPEData>) => {
      state.floorFPEData = action.payload;
    },
    setFloorAPIData: (state, action: PayloadAction<IFloorAPIData>) => {
      state.floorAPIData = action.payload;
    },
    setFloorBasicData: (state, action: PayloadAction<IFloorBasicData[]>) => {
      state.floorBasicData = action.payload;
    },
    setDescriptionEditMode: (state, action: PayloadAction<boolean>) => {
      state.descriptionEditMode = action.payload;
    },
    startFetching: state => {
      state.loading = true
    },
    endFetching: state => {
      state.loading = false
    },
    startFetchingFpe: state => {
      state.loadingFpe = true
    },
    endFetchingFpe: state => {
      state.loadingFpe = false
    }
  },
});

export const {
  setSceneId,
  setFloorAPIData,
  setFloorFPEData,
  setFloorBasicData,
  startFetching,
  endFetching,
  setDescriptionEditMode,
  startFetchingFpe,
  endFetchingFpe
} = floorPlanSlice.actions;

export const selectFloorAPIData = (state: RootState) => state.floorPlan.floorAPIData;
export const selectFloorFPEData = (state: RootState) => state.floorPlan.floorFPEData;
export const selectFloorBasicData = (state: RootState) => state.floorPlan.floorBasicData;

export const selectScene = (sceneId: string): AppThunk => dispatch => {
  dispatch(setSceneId(sceneId))
  dispatch(setActiveSpaceId(null))
  dispatch(setActiveAssetName(null))
}


export const getFloorData = (sceneId: string): AppThunk => dispatch => {
  dispatch(startFetching())

  Axios.get(`/v2/floor/${sceneId}/custom-field/properties.customFields`)
    .then((response: any) => {
      const listingPageCustomdata = response?.data?.listingPage
      const platformDemoCustomdata = response?.data?.platformDemo
      const floordata = {
        address: listingPageCustomdata?.data?.address,
        number: listingPageCustomdata?.data?.name,
        image: platformDemoCustomdata?.image || '',
        description: platformDemoCustomdata?.floorDescription,
        pricelist: platformDemoCustomdata?.priceList || [],
        latitude: listingPageCustomdata?.data?.location?.latitude,
        longitude: listingPageCustomdata?.data?.location?.longitude
      }
      dispatch(setFloorAPIData(floordata))

    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
      }
    }).finally(() => {
      dispatch(endFetching())
    })
};

export const getFloorsData = (scenesId: string[], config: AxiosRequestConfig = {}): AppThunk => dispatch => {
  let floorBasicData: IFloorBasicData[] = []
  const allRequests = scenesId.map(sceneId => Axios.get(`/v2/floor/${sceneId}/custom-field/properties.customFields`,config))

  const allRequestsResponses = Axios.all(allRequests)

  allRequestsResponses.then(Axios.spread((...responses) => {
    floorBasicData = responses.map((response, index) => {
      const listingPageCustomdata = response?.data?.listingPage

      return {
        id: scenesId[index],
        name: listingPageCustomdata?.data?.address,
        coordinates: {
          lat: listingPageCustomdata?.data?.location?.latitude,
          long: listingPageCustomdata?.data?.location?.longitude
        }
      }
    })

    dispatch(setFloorBasicData(floorBasicData))
  })).catch(errors => {
    console.error(errors)
  })
};

export const saveFloorAssetPrice = (price: number): AppThunk => (dispatch, getState) => {
  dispatch(startFetching())

  const sceneId = getState().floorPlan.sceneId;
  const activeAssetProductId = getState().activeAsset.productId;

  if (!sceneId || !activeAssetProductId) return;
  Axios.put(
    `/v2/floor/${sceneId}/custom-field/properties.customFields.platformDemo.priceList.${activeAssetProductId}`,
    JSON.stringify(price),
    {
      headers: { "Content-type": "application/json" }
    }).then(() => {
      dispatch(getFloorData(sceneId))
      dispatch(setDescriptionEditMode(false))

    }).catch((errorResponse) => {
      console.log(errorResponse)
    }).finally(() => {
    })
  dispatch(endFetching())

}

export const saveFloorDescriptionAndImage = (values: IDescriptionImage): AppThunk => async (dispatch, getState) => {
  dispatch(startFetching())

  const sceneId = getState().floorPlan.sceneId;
  if (!sceneId) return;
  await Axios.put(
    `/v2/floor/${sceneId}/custom-field/properties.customFields.platformDemo.floorDescription`,
    JSON.stringify(values.description),
    {
      headers: { "Content-type": "application/json" }
    })

  await Axios.put(
    `/v2/floor/${sceneId}/custom-field/properties.customFields.platformDemo.image`,
    JSON.stringify(values.image),
    {
      headers: { "Content-type": "application/json" }
    })

  dispatch(getFloorData(sceneId))
  dispatch(setDescriptionEditMode(false))

  dispatch(endFetching())

}


export const updateFloorImageSetUrl = (newImages: string[]): AppThunk => (dispatch, getState) => {
  dispatch(startFetching())

  const sceneId = getState().floorPlan.sceneId;

  Axios.put(
    `/v2/floor/${sceneId}/custom-field/properties.customFields.platformDemo.images`,
    newImages).then(() => {
      dispatch(getFloorData(sceneId!))
    }).catch((errorResponse) => {
      console.log(errorResponse)
    }).finally(() => {
      dispatch(endFetching())
    })

}


export const saveSpaceDescriptionAndImage = (values: IDescriptionImage): AppThunk => async (dispatch, getState) => {
  dispatch(startFetching())

  const spaceId = getState().activeSpace.id;
  if (!spaceId) return;
  await Axios.put(
    `/v2/space/${spaceId}/custom-field/properties.customFields.platformDemo.spaceDescription`,
    JSON.stringify(values.description),
    {
      headers: { "Content-type": "application/json" }
    })
  await Axios.put(
    `/v2/space/${spaceId}/custom-field/properties.customFields.platformDemo.image`,
    JSON.stringify(values.image),
    {
      headers: { "Content-type": "application/json" }
    })
  dispatch(getSpaceData(spaceId))
  dispatch(setDescriptionEditMode(false))
  dispatch(endFetching())
}


export const updateSpaceImageSetUrl = (newImages: string[]): AppThunk => (dispatch, getState) => {
  dispatch(startFetching())
  const activeSpaceId = getState().activeSpace.id;

  Axios.put(
    `/v2/space/${activeSpaceId}/custom-field/properties.customFields.platformDemo.images`,
    newImages).then(() => {
      dispatch(getSpaceData(activeSpaceId!))
    }).catch((errorResponse) => {
      console.log(errorResponse)
    }).finally(() => {
      dispatch(endFetching())
    })
}




export default floorPlanSlice.reducer;
