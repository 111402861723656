import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { AppThunk } from './store';

interface ActiveAssetState {
    name: string | null;
    description: string | null;
    price: string | null;
    image: string | null;
    width: string | null;
    height: string | null;
    length: string | null;
    color: string | null;
    brand: string | null;
    productId: string | null;
    loading: boolean
}

const initialState: ActiveAssetState = {
    name: null,
    description: null,
    price: null,
    image: null,
    width: null,
    height: null,
    length: null,
    color: null,
    brand: null,
    productId: null,
    loading: false
};

export const activeAssetSlice = createSlice({
    name: 'activeAsset',
    initialState,
    reducers: {
        setProductId: (state, action: PayloadAction<string | null>) => {
            state.productId = action.payload;
          },
          setName: (state, action: PayloadAction<string | null>) => {
            state.name = action.payload;
          },
          setDescription: (state, action: PayloadAction<string>) => {
            state.description = action.payload;
          },
          setPrice: (state, action: PayloadAction<string>) => {
            state.price = action.payload;
          },
          setImage: (state, action: PayloadAction<string>) => {
            state.image = action.payload;
          },
          setWidth: (state, action: PayloadAction<string>) => {
            state.width = action.payload;
          },
          setHeight: (state, action: PayloadAction<string>) => {
            state.height = action.payload;
          },
          setLength: (state, action: PayloadAction<string>) => {
            state.length = action.payload;
          },
          setColor: (state, action: PayloadAction<string>) => {
            state.color = action.payload;
          },
          setBrand: (state, action: PayloadAction<string>) => {
            state.brand = action.payload;
          },
          startFetching: state => {
            state.loading = true
        },
        endFetching: state => {
            state.loading = false
        }
    },
});

export const {
    setName,
    setBrand,
    setColor,
    setLength,
    setWidth,
    setHeight,
    setImage,
    setPrice,
    setDescription,
    setProductId,
    startFetching,
    endFetching
} = activeAssetSlice.actions;

export const getAssetData = (productId: string): AppThunk => dispatch => {
    dispatch(startFetching())
  
    Axios.get(`https://storage.3d.io/archilogic/product/json/${productId}.json`)
      .then(response => {
        const data = response.data
  
        dispatch(setImage(`https://storage.3d.io${data?.preview}`))
        dispatch(setColor(data?.colours?.filter((it: string) => !it.includes("#")).join(', ')))
        dispatch(setBrand(data?.manufacturer))
        dispatch(setDescription(data?.description))
        dispatch(setLength(data?.boundingBox?.length))
        dispatch(setHeight(data?.boundingBox?.height))
        dispatch(setWidth(data?.boundingBox?.width))
        dispatch(setProductId(productId))
  
      }).catch((errorResponse) => {
        console.log(errorResponse)
      }).finally(() => {
        dispatch(endFetching())
      })
  };
  


export default activeAssetSlice.reducer;
